import React, { useEffect, useState } from "react";
import Slider from "infinite-react-carousel";
import { AspectRatioBox, Image, Skeleton } from "@chakra-ui/core";
import styles from "./carousel.module.css";

function Carousel({ category, videos, handlePosterClick }) {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1050);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 1050);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.main}>
      <h3 className={styles.category}>
        {category.charAt(0).toUpperCase() + category.slice(1)}
      </h3>
      <Slider
        key={category}
        slidesToShow={isDesktop ? 3 : 2}
        rows={isDesktop || videos[0].length <= 2 ? 1 : 2}
        centerMode={isDesktop}
        centerPadding={isDesktop ? 20 : 10}
        duration={300}
        shift={isDesktop ? 20 : 10}
        prevArrow={
          <span>
            <i className="fa-solid fa-chevron-left"></i>
          </span>
        }
        nextArrow={
          <span>
            <i className="fa-solid fa-chevron-right"></i>
          </span>
        }
      >
        {videos[0].map((video) => (
          <AspectRatioBox
            key={video._id}
            ratio={isDesktop ? 16 / 9 : 4 / 5}
            className={styles.poster}
          >
            <Image
              src={`${
                isDesktop
                  ? process.env.REACT_APP_API_URL + "/uploads/" + video.filename
                  : process.env.REACT_APP_API_URL +
                    "/uploads/" +
                    video.filenameMobile
              }`}
              alt="film"
              objectFit="cover"
              onClick={() => {
                handlePosterClick(video._id);
              }}
            />
          </AspectRatioBox>
        ))}
      </Slider>
    </div>
  );
}

export default Carousel;
